<i18n lang="yaml">
pt:
  group: 'Grupo'
  item: 'Item'
en:
  group: 'Group'
  item: 'Item'
</i18n>
<template>
  <div class="link-list">
    <v-list-group
      v-if="nested"
      :value="active"
      class="link-list__group"
      :aria-label="`${t('group')} ${title}`"
    >
      <template #activator="{ props }">
        <v-list-item
          v-bind="props"
          :title="title"
        >
          {{ title }}
        </v-list-item>
      </template>

      <template v-for="item in items">
        <LinkList
          v-if="item.items"
          :key="item.id"
          :items="item.items"
          :title="item.title"
          :nav="nav"
          :selected-id="selectedId"
          nested
          :active="item.active"
          @link-clicked="$emit('linkClicked')"
        />

        <v-list-item
          v-else
          :key="`else-${item.id}`"
          :to="item.to"
          :class="{ 'v-list-item--active text-primary': isItemActive(item.to) }"
          exact
          no-action
          color="primary"
          :aria-label="`${t('item')} ${item.title}`"
          @click="$emit('linkClicked')"
        >
          <v-list-item-title :title="item.title">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list-group>

    <v-list
      v-else
      :nav="nav"
      density="compact"
    >
      <template v-for="item in items">
        <LinkList
          v-if="item.items"
          :key="item.id"
          :items="item.items"
          :title="item.title"
          :nav="nav"
          :selected-id="selectedId"
          nested
          :active="item.active"
          @link-clicked="$emit('linkClicked')"
        />

        <v-list-item
          v-else
          :key="`else-${item.id}`"
          :to="item.to"
          :class="{ 'v-list-item--active text-primary': isItemActive(item.to) }"
          exact
          no-action
          color="primary"
          :aria-label="`${t('item')} ${item.title}`"
          @click="$emit('linkClicked')"
        >
          <v-list-item-title :title="item.title">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import { everyItemOfArrayShouldHave } from 'vue-prop-validation-helper';

export default {
  name: 'LinkList',
  props: {
    selectedId: {
      type: [String, null],
      default: null,
    },
    nav: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
      validator: everyItemOfArrayShouldHave(['title', 'id']),
    },
    title: {
      type: String,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['linkClicked'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  methods: {
    isItemActive(path) {
      if (!this.selectedId || !path) return false;

      return path.includes(this.selectedId);
    },
  },
};
</script>

<style lang="scss">
.link-list {
  & .v-list-group__items {
    padding-left: 16px !important;

    & .v-list-item {
      padding-left: 16px !important;
    }
  }

  // fighting against the specificity :sweat_smile:
  .link-list__group.link-list__group {
    color: inherit !important;
  }
}
</style>
