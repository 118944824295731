<template>
  <v-menu
    :disabled="!hasItems"
    location="isSubmenu ? 'right' : 'bottom'"
    :open-on-hover="isSubmenu"
  >
    <template #activator="{ props }">
      <deck-tabs-tab
        v-bind="props"
        :to="localePath(item.to)"
        :exact="true"
        :text="item.title"
        class="public-topbar-page-button"
        :class="{ 'public-topbar-page-button--menu': isSubmenu }"
      >
        <template
          v-if="isGroup"
          #append-tab
        >
          <deck-icon
            size="small"
            name="chevron-down"
            color="grey"
          />
        </template>
      </deck-tabs-tab>
    </template>
    <v-list
      v-if="hasItems"
      class="public-topbar-page-button__submenu"
      @click.stop.prevent
    >
      <v-list-item
        v-for="(groupItem, index) in item.items"
        :key="index"
        density="compact"
      >
        <PublicTopbarPageButton
          :item="groupItem"
          is-submenu
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'PublicTopbarPageButton',
  props: {
    item: { type: Object, default: () => ({}) },
    isSubmenu: { type: Boolean, default: false },
  },
  computed: {
    isGroup() {
      return (this.item.to === undefined) && (this.item.items !== undefined);
    },
    hasItems() {
      return this.item.items?.length > 0;
    },
  },
};
</script>

<style lang="scss">
.public-topbar-page-button--menu {
  width: 100%;
  height: 100%;
}

.public-topbar-page-button__submenu {
  padding: 0;

  .v-list-item {
    padding: 0;
    height: 52px;
  }
}
</style>
